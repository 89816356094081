.login-wrapper {
  width: 99vw;
  height: 100vh;
  position: relative;
  background-image: url("https://i.pinimg.com/originals/b4/ae/af/b4aeafbee54e7459549333273784bec8.jpg");
  background-size: cover;
}

.login-wrapper::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
}
.login {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 500px;
  height: 500px;
  background-color: white;

  position: absolute;
  top: 25%;
  left: 15%;

  border-radius: 10px;
}

.heading {
  display: flex;
  margin-top: 50px;
  width: 100%;
  justify-content: space-around;
}

.heading .login-logo {
  width: 90px;
  height: 80px;
}

.heading .login-title {
  /* margin-right: auto; */
}

/* Btn */
.btn {
  width: 90%;
  background-color: brown;
  margin: 0 auto;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
