.customer {
  width: 95%;
  margin: 4rem auto 4rem auto;
  position: relative;

  .ant-table-wrapper {
    margin-top: 3rem;
  }

  .addBtn {
    text-decoration: none;
    background-color: var(--Red);
    color: var(--White);
    padding: 0.3rem 1rem;
    border-radius: 0.375rem;
    font-weight: 600;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;

    svg {
      font-size: 1.15rem;
    }
  }
}
