.progress-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.file-name {
  font-weight: 500;
}

/* progress-bar-controller */
.progress-bar-controller {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

/* Box */
.box {
  width: 16px;
  height: 16px;
  border-radius: 2.4px;
  opacity: 20%;
  background: #bd0008;
}

/* cross */
.cross {
  width: 32px;
  height: 32px;
  border-radius: 24px;
  padding: 8px;
  background: #d2d5da;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #bd0008;
}

progress {
  display: block;
  
}

/* Determinate: */

progress[value] {
  /* style rules */
  height: 32px;
  border-radius: 100px;
  background: #f5f5f5;
  border: none;
  width: 783px;
   border-radius: 100px;
}
progress[value]::-webkit-progress-bar {
  /* style rules */
   border-radius: 100px;
}
progress[value]::-webkit-progress-value {
  /* style rules */
   border-radius: 100px;
}
progress[value]::-moz-progress-bar {
  /* style rules */
   border-radius: 100px;
}

progress::-moz-progress-bar {
  background: #bd0008;
   border-radius: 100px;
}
progress::-webkit-progress-value {
  background: #bd0008;
  border-radius: 100px;
}
progress {
  color: #bd0008;
}
