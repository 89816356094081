@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');

@import './components';

* {
  font-family: 'Inter';
}

a {
  text-decoration: none;
  color: black;
}
